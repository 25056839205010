import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { decodePostRequestBodyContent } from "../helpers/encryption";
import {
  getLineList,
  getGroupList,
  getZoneList,
  getStationList,
  getPopularLinks,
} from "../services/smrtResource.service";
import { NSEWL } from "../constants/values";

const state = () => ({
  lines: [],
  groups: [],
  zones: [],
  stations: [],
  stationSkip: 0,
  stationTotal: 0,
  popularLinks: [],
});

const getters = {
  lineOptions(state) {
    return state.lines.map(({ lineId, lineName }) => ({
      value: lineId,
      label: lineName,
    }));
  },
  stationOptions(state) {
    return state.stations
      .filter(
        (s1, index) =>
          state.stations.findIndex(
            // dealing with unicode 8203
            (s2) =>
              s2.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") ===
              s1.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "")
          ) === index
      )
      .map(({ stationId, stationName, abbreviation }) => ({
        value: stationId,
        name: stationName,
        label: `${stationName} (${abbreviation})`,
      }));
  },
  // custom options list to filter out duplicated station name
  wayfindingStationOptions: (state) =>  
    state.stations
      .filter(
        (s1, index) =>
          state.stations.findIndex(
            // dealing with unicode 8203
            (s2) =>
              s2.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") ===
              s1.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "")
          ) === index
      )
      .map(({ stationId, stationName, abbreviation }) => {
        abbreviation = abbreviation.replace("CBSH", "BSH, CBSH");
        abbreviation = abbreviation.replace("CPYL", "PYL, CPYL");
        abbreviation = abbreviation.replace("CMRB", "MRB, CMRB");
        abbreviation = abbreviation.replace("CBNV", "BNV, CBNV");
        abbreviation = abbreviation.replace("CDBG", "DBG, CDBG");
        return {
          value: stationId,
          name: stationName,
          label: `${stationName} (${abbreviation})`,
        }
      }),
  stationsByZoneId: (state) => (zoneId) => {
    return state.stations.filter(s => {
      const zones = get(s, 'zoneStations', []);
      return zones && zones.some(sz => sz && sz.zoneId == zoneId);
    });
  },
  stationsByGroupId: (state) => (groupId) => {
    return state.stations.filter(s => {
      const zones = get(s, 'zoneStations', []);
      return zones && zones.some(sz => sz && sz.groupId == groupId);
    });
  },
  allZoneStationOptionsByLineId: (state) => {
    let zoneStationOptions = [];
    state.stations
      .filter(
        (s1, index) =>
          state.stations.findIndex(
            // dealing with unicode 8203
            (s2) =>
              s2.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") ===
              s1.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "")
          ) === index
      )
      .forEach(({ stationId, stationName, abbreviation, zoneStations }) => {
        zoneStations.forEach(({ lineId, zoneStationId }) => {
          const line = state.lines.filter(l => l.lineId === lineId);
          const lineName = get(line, '0.lineName', '');
          const isNSEWL = lineName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") ===
          NSEWL.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "");
          let abbr = abbreviation;
          if (isNSEWL) {
            abbr = abbr.replace("CBSH", "BSH");
            abbr = abbr.replace("CPYL", "PYL");
            abbr = abbr.replace("CMRB", "MRB");
            abbr = abbr.replace("CBNV", "BNV");
            abbr = abbr.replace("CDBG", "DBG");
          }
          zoneStationOptions.push({
            value: zoneStationId,
            lineId,
            stationId,
            label: `${stationName} (${abbr})`,
          })
        }) 
        
      });
    return zoneStationOptions;
  },
  stationOptionsByLineId: (state) => (selectedlineId, stationId) => {
    // console.log('所有站线', state.stations);
    const line = state.lines.filter(l => l.lineId === selectedlineId);
    const lineName = get(line, '0.lineName', '');
    const isNSEWL = lineName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") === NSEWL.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") || lineName === NSEWL;
    let fld = state.stations.filter((s1, index) =>
      state.stations.findIndex((s2) => s2.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") === s1.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "")) === index);
    !stationId ? fld = fld.filter(({ zoneStations }) =>
        zoneStations.some(({ lineId }) => lineId === selectedlineId)
    ) : null;
    const list = fld.map(({ stationId, stationName, abbreviation }) => {
      if (isNSEWL) {
        abbreviation = abbreviation.replace("CBSH", "BSH");
        abbreviation = abbreviation.replace("CPYL", "PYL");
        abbreviation = abbreviation.replace("CMRB", "MRB");
        abbreviation = abbreviation.replace("CBNV", "BNV");
        abbreviation = abbreviation.replace("CDBG", "DBG");
      }
      return {
        value: stationId,
        name: stationName,
        label: `${stationName} (${abbreviation})`,
      }
    });
    // console.log(stationId,'list-----------',list);
    if(stationId){
      const stationData = list.find(r => {
        return r.value === stationId
      })
      return stationData || null
    }else {
      return list || [];
    }
  },
  zoneStationOptionsByLineId: (state) => (selectedlineId) => {
    const line = state.lines.filter(l => l.lineId === selectedlineId);
    const lineName = get(line, '0.lineName', '');
    const isNSEWL = lineName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") ===
    NSEWL.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "");
    let zoneStationOptions = [];
    state.stations
      .filter(
        (s1, index) =>
          state.stations.findIndex(
            // dealing with unicode 8203
            (s2) =>
              s2.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "") ===
              s1.stationName.replace(/(^[\s\u200b]*|[\s\u200b]*$)/g, "")
          ) === index
      )
      .forEach(({ stationId, stationName, abbreviation, zoneStations }) => {
        zoneStations
          .filter(({ lineId }) => lineId === selectedlineId)
          .forEach(({ zoneStationId }) => {
            let abbr = abbreviation;
            if (isNSEWL) {
              abbr = abbr.replace("CBSH", "BSH");
              abbr = abbr.replace("CPYL", "PYL");
              abbr = abbr.replace("CMRB", "MRB");
              abbr = abbr.replace("CBNV", "BNV");
              abbr = abbr.replace("CDBG", "DBG");
            }

            zoneStationOptions.push({
              value: zoneStationId,
              lineId: selectedlineId,
              stationId: stationId,
              label: `${stationName} (${abbr})`,
            })
          })
      })
    
    return zoneStationOptions;
  },
  lineFromStationId: (state) => (stationId) => {
    const station = state.stations.find((s) => s.stationId === stationId);
    if (station)
      return state.lines.find((l) =>
        station.zoneStations.some(({ lineId }) => lineId === l.lineId)
      );
    return null;
  },
  isSameZone: (state) => (station1Id, station2Id) => {
    const station1 = state.stations.find(s => s.stationId == station1Id);
    const station2 = state.stations.find(s => s.stationId == station2Id);
    const station1Zones = get(station1, 'zoneStations', []);
    const station2Zones = get(station2, 'zoneStations', []);
    const station1ZoneIds = station1Zones.map(sz => get(sz, 'zoneId'));
    const station2ZoneIds = station2Zones.map(sz => get(sz, 'zoneId'));
    return station1ZoneIds.some(id => !isEmpty(id) && station2ZoneIds.includes(id));
  },
  isSameGroup: (state) => (station1Id, station2Id) => {
    const station1 = state.stations.find(s => s.stationId == station1Id);
    const station2 = state.stations.find(s => s.stationId == station2Id);
    const station1Zones = get(station1, 'zoneStations', []);
    const station2Zones = get(station2, 'zoneStations', []);
    const station1GroupIds = station1Zones.map(sz => get(sz, 'groupId'));
    const station2GroupIds = station2Zones.map(sz => get(sz, 'groupId'));
    return station1GroupIds.some(id => !isEmpty(id) && station2GroupIds.includes(id));
  },
  isSameLine: (state) => (station1Id, station2Id) => {
    const station1 = state.stations.find(s => s.stationId == station1Id);
    const station2 = state.stations.find(s => s.stationId == station2Id);
    return !isEmpty(station1) && !isEmpty(station2) &&
      get(station1, 'lineId') === get(station2, 'lineId');
  },
  popularLinks: state => state.popularLinks
};

const mutations = {
  setLines(state, lines) {
    state.lines = lines;
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  setZones(state, zones) {
    state.zones = zones;
  },
  setStationData(state, { list = [], total = 0 }) {
    state.stations = list;
    state.stationTotal = total;
    state.stationSkip =
      state.stationSkip + 100 < total ? state.stationSkip + 100 : total;
  },
  setPopularLinks(state, links) {
    try {
      state.popularLinks = typeof decodePostRequestBodyContent(links) === 'object' ? JSON.parse(decodePostRequestBodyContent(links)) : [];
    } catch (e) {
      console.log(e);
    }
  },
  reset(state) {
    state.lines = [];
    state.groups = [];
    state.zones = [];
    (state.stations = []), (state.stationSkip = 0);
    state.stationTotal = 0;
  },
};

const actions = {
  getLineList: async function ({ commit }) {
    const result = await getLineList({ skip: 0, take: 100 });
    if (result.success) {
      commit("setLines", get(result, "payload.list", []));
      return result;
    } else {
      return result;
    }
  },
  getGroupList: async function ({ commit }) {
    const result = await getGroupList({ skip: 0, take: 100 });
    if (result.success) {
      commit("setGroups", get(result, "payload.list", []));
      return result;
    } else {
      return result;
    }
  },
  getZoneList: async function ({ commit }) {
    const result = await getZoneList({ skip: 0, take: 100 });
    if (result.success) {
      commit("setZones", get(result, "payload.list", []));
      return result;
    } else {
      return result;
    }
  },
  getStationList: async function ({ state, commit }) {
    if (state.stationTotal > 0 && state.stationSkip >= state.stationTotal)
      return;

    const result = await getStationList({
      skip: state.stationSkip,
      take: 100,
    });
    if (result.success) {
      commit("setStationData", get(result, "payload", {}));
      return result;
    } else {
      return result;
    }
  },
  getPopularLinks: async function({ commit }) {
    const result = await getPopularLinks();
    if (result.success) {
      commit("setPopularLinks", get(result, "payload.links", ""));
      return result;
    } else {
      return result;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
