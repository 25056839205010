import isEqual from "lodash.isequal";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";
import { createStore } from "vuex";
//import SecureLS from "secure-ls";
import auth from "./auth/index";
import adminResource from "./adminResource.module";
import smrtResource from "./smrtResource.module";
import currentRoster from "./rosterDetails.module";
import cashManagement from "./cashManagement";
import inventoryManagement from "./inventory";
import opsComms from "./opsComms.module";
import atoms from "./atoms.module";
import notifications from "./notifications.module";
import selectedStation from "./selectedStation.module";
import apiStatus from "./apiStatus.module";
import offlineData from "./offlineData.module";
import display from "./display.module";
import { Database } from "./offlinePlugin";
import { clearStorage } from "@/helpers/sessionStorage";

let offlineDB = new Database();

const dexiePlugin = (store) => {
  let prevState = cloneDeep(store.state);
  store.subscribe((mutation, state) => {
    // console.log(mutation);
    let nextState = cloneDeep(state);
    // console.log(get(nextState, "auth.token"));
    if (!isEqual(prevState, nextState)) {
      offlineDB.updateOfflineData(JSON.stringify(state));
    }
    prevState = nextState;
  });
};

const store = createStore({
  modules: {
    auth,
    adminResource,
    smrtResource,
    currentRoster,
    cashManagement,
    inventoryManagement,
    opsComms,
    atoms,
    offlineData,
    apiStatus,
    notifications,
    selectedStation,
    display,
  },
  plugins: [dexiePlugin],
  mutations: {
    mutateStorage: function (state, payload) {
      if (get(payload, "adminResource", false))
        state.adminResource = {
          ...state.adminResource,
          ...get(payload, "adminResource")
        };
      if (get(payload, "apiStatus", false))
        state.apiStatus = {
          ...state.apiStatus,
          ...get(payload, "apiStatus")
        };
      if (get(payload, "auth", false)) state.auth = {
        ...state.auth,
        ...get(payload, "auth")
      };
      if (get(payload, "cashManagement", false))
        state.cashManagement = {
          ...state.cashManagement,
          ...get(payload, "cashManagement")
        };
      if (get(payload, "currentRoster", false))
        state.currentRoster = {
          ...state.currentRoster,
          ...get(payload, "currentRoster")
        };
      if (get(payload, "inventoryManagement", false))
        state.inventoryManagement = {
          ...state.inventoryManagement,
          ...get(payload, "inventoryManagement")
        };
      if (get(payload, "opsComms", false))
        state.opsComms = {
          ...state.opsComms,
          ...get(payload, "opsComms")
        };
      if (get(payload, "atoms", false)) 
        state.atoms = {
          ...state.atoms,
          ...get(payload, "atoms")
        };
      if (get(payload, "notifications", false))
        state.notifications = {
          ...state.notifications,
          ...get(payload, "notifications")
        };
      if (get(payload, "offlineData", false))
        state.offlineData = {
          ...state.offlineData,
          ...get(payload, "offlineData")
        };
      if (get(payload, "selectedStation", false))
        state.selectedStation = {
          ...state.selectedStation,
          ...get(payload, "selectedStation")
        };
      if (get(payload, "smrtResource", false))
        state.smrtResource = {
          ...state.smrtResource,
          ...get(payload, "smrtResource")
        };
    },
  },
  actions: {
    loadDataFromStorage: async function ({ commit }) {
      if (offlineDB.database == null) await offlineDB.init();
      let offlineData = await offlineDB.getOfflineData();
      commit("mutateStorage", offlineData);
      return offlineData;
    },
    loadInitialData: async function ({ getters, dispatch }) {
      if (!getters["auth/isAuthenticated"]) {
        return;
      }

      if (getters["auth/isSysAdmin"] || getters["auth/canCreateOpsCommCirculars"]) {
        dispatch("adminResource/getRoles");
      }

      dispatch('auth/refreshUserInfo')
      dispatch("smrtResource/getPopularLinks");
      dispatch("smrtResource/getLineList");
      dispatch("smrtResource/getGroupList");
      dispatch("smrtResource/getZoneList");
      dispatch("smrtResource/getStationList");
      if (getters['auth/canViewManpower'])
        await dispatch("currentRoster/getCurrentRoster");
      await dispatch("selectedStation/initData");
      if (!getters["auth/isSysAdmin"]) {
        dispatch("cashManagement/loadInitialData");
        dispatch("inventoryManagement/loadInitialData");
      }
    },
    clearData({ commit, dispatch }) {
      commit("adminResource/reset");
      commit("smrtResource/reset");
      commit("currentRoster/reset");
      commit("apiStatus/reset");
      commit("notifications/reset");
      commit("selectedStation/reset");
      dispatch("cashManagement/reset");
      dispatch("inventoryManagement/reset");
      commit("opsComms/reset");
      commit("atoms/reset");
      commit("offlineData/reset");
      dispatch("display/setup");
    },
    login: async function ({ state, dispatch }, loginData) {
      const lastUserId = state.auth.userId;
      const loginResult = await dispatch("auth/login", loginData);
      if (loginResult.success) {
        const isDifferentUser = lastUserId !== state.auth.userId;
        if (isDifferentUser) {
          dispatch("clearData");
        }
      }
        
      await dispatch("auth/refreshUserInfo");
      dispatch("loadInitialData");
      clearStorage();
      return loginResult;
    },
    logout: async function ({ dispatch }) {
      const logoutResult = await dispatch("auth/logout");
      if (logoutResult.success) {
        dispatch("clearData");
      }
      return logoutResult;
    },
    handle401Error: function ({ commit }) {
      // -----------监测token
      const tokenObj = window.localStorage.getItem("tokenHistory");
      const deviceId = window.localStorage.getItem("asomsDeviceId");
      const tokenData = tokenObj ? JSON.parse(tokenObj) : [];
      tokenData.push({ 
        txt: '遇到401退出 - 清空了token',
        enTxt: 'Encountered 401 - Cleared Token',
        date: new Date().toLocaleString(), 
        url: window.location.href.split('#')[1],
        asomsDeviceId: deviceId || null,
        token: null,
      })
      window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData) );
      // -----------监测token
      
      commit("auth/setToken", null);
    },
  },
  strict: process.env.NODE_ENV !== "production",
});

export default store;
